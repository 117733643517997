<template>
	<div class="row">
		<div class="card-item">
			<div class="row">
				<div
					class="d-md-flex d-none col-md-4 col-12"
					v-for="item in items"
					:key="item.id"
				>
					<HomeKeyFeaturesCardItem :item="item" />
				</div>
			</div>
			<carousel
				class="carousel d-md-none d-flex"
				:autoWidth="true"
				:loop="true"
				:dots="false"
				:nav="false"
			>
				<div v-for="item in items" :key="item.id">
					<HomeKeyFeaturesCardItem :item="item" />
				</div>
			</carousel>
		</div>
		<img
			src="../../../assets/images/key-features-left-mobile.webp"
			class="key-features-icon-left-mobile d-sm-none d-flex"
			alt=""
		/>
	</div>
</template>

<script>
	import carousel from "vue-owl-carousel";
	import HomeKeyFeaturesCardItem from "./HomeKeyFeaturesCardItem.vue";
	export default {
		components: {
			carousel,
			HomeKeyFeaturesCardItem,
		},
		data() {
			return {
				items: [
					{
						id: 1,
						img: "key1.webp",
						title: "Akses Penghasilan",
						caption:
							"Akses penghasilan prorata Anda kapanpun Anda butuh, hanya dalam genggaman.",
					},
					{
						id: 2,
						img: "key2.webp",
						title: "Transaksi Aman & Cepat",
						caption:
							"Tarik gaji tanpa ribet, langsung cair ke rekening Anda dalam 1x24 jam setelah approval",
					},
					{
						id: 3,
						img: "key3.webp",
						title: "Poin Hadiah",
						caption:
							"Dapatkan poin hadiah di setiap transaksi dan tunggu kejutan berikutnya",
					},
				],
			};
		},
	};
</script>

<style lang="scss" scoped>
	.card-item {
		position: relative;
		z-index: 99;

		.carousel {
			margin-left: -12px;
			margin-right: -12px;
		}
	}
	.row {
		position: relative;
		@media (max-width: 767.98px) {
			background: rgba(21, 37, 3, 1);
		}

		.key-features-icon-left-mobile {
			position: absolute;
			bottom: 0;
			z-index: 9;
			width: 100px;
			margin-left: -12px;
		}
	}
</style>
