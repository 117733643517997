<template>
	<section class="footer">
		<img
			src="../../assets/images/footer-bottom.webp"
			class="footer-bottom d-sm-block d-none"
			alt=""
		/>
		<img
			src="../../assets/images/footer-bottom-mobile.webp"
			class="footer-bottom d-sm-none d-block"
			alt=""
		/>
		<div class="container">
			<div class="row">
				<div class="text col-md-9 col-12">
					<h3 class="color--white mb-4 font-weight-semibold">
						Akses penghasilan lebih mudah<br class="d-md-block d-none" />
						dengan download hakku sekarang
					</h3>
					<button
						type="button"
						class="btn py-0 px-0 d-flex"
						style="box-shadow: none"
						aria-label="playstore-icon"
					>
						<img
							class="playstore-img"
							src="../../assets/images/playstore.webp"
							alt=""
						/>
					</button>
				</div>
				<div class="col-md-3 col-12">
					<img
						src="../../assets/images/footer-phone.webp"
						class="footer-phone mx-auto d-block"
						alt=""
					/>
				</div>
			</div>
			<div class="footer-icon">
				<HakkuIconWhite />
				<p class="p-14 mt-2 color--white d-flex">©2022 All rights reserved</p>
			</div>
		</div>

		<img
			src="../../assets/images/footer-right-mobile.webp"
			class="footer-icon-right-mobile d-sm-none d-flex"
			alt=""
		/>
	</section>
</template>

<script>
	import HakkuIconWhite from "../icons/HakkuIconWhite.vue";
	export default {
		components: { HakkuIconWhite },
	};
</script>

<style lang="scss" scoped>
	.footer {
		background: linear-gradient(180deg, #003399 82.88%, #598fe0 151.3%);
		padding-top: 54px;
		padding-bottom: 0px;
		position: relative;
		@media (max-width: 991.98px) {
			padding-top: 40px;
			padding-bottom: 124px;
		}
		@media (max-width: 767.98px) {
			background: linear-gradient(180deg, #003399 35.22%, #598fe0 72.26%);
		}

		.row {
			position: relative;
			z-index: 9;
		}

		.text {
			z-index: 100;
			@media (max-width: 767.98px) {
				margin-bottom: 40px;
			}
		}

		.playstore-img {
			width: 196px;
			@media (max-width: 767.98px) {
				width: 147.43px;
			}
		}

		.footer-bottom {
			position: absolute;
			bottom: 0;
			width: 100%;
			z-index: 99;
		}

		.footer-phone {
			max-width: 254.78px;
			width: 100%;
			@media (max-width: 767.98px) {
				width: 163px;
			}
		}

		.footer-icon {
			position: absolute;
			left: 50%;
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
			bottom: 90px;
			z-index: 99;
			@media (max-width: 991.98px) {
				bottom: 0px;
			}
			@media (max-width: 767.98px) {
				bottom: -50px;
			}
		}

		.footer-icon-right-mobile {
			position: absolute;
			right: 0;
			top: 0;
			width: 125px;
		}
	}
</style>
