<template>
	<div class="card d-flex flex-column align-items-center text-center">
		<div>
			<img
				:src="require('../../../assets/images/' + item.img)"
				class="card-img mb-2"
				alt="Key Items"
			/>
		</div>
		<h5 class="color--black-primary font-weight-semibold mb-2">
			{{ item.title }}
		</h5>
		<h6 class="caption color--black-primary text-center">
			{{ item.caption }}
		</h6>
	</div>
</template>

<script>
	export default {
		props: {
			item: {
				type: Object,
				default: () => {},
			},
		},
	};
</script>

<style lang="scss" scoped>
	.card {
		padding: 24px 38px;
		height: 100%;
		border-radius: 20px;
		box-shadow: 0px 4px 15px 0px rgba(0, 51, 153, 0.25);

		@media (max-width: 767.98px) {
			padding: 26px 16px;
			margin-left: 12px;
			width: 301px;
		}

		.card-img {
			width: 64px;
			@media (max-width: 767.98px) {
				width: 48px;
			}
		}

		.caption {
			opacity: 0.7;
		}
	}
</style>
