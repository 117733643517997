<template>
	<div>
		<svg
			class="mx-auto d-block"
			width="108"
			height="24"
			viewBox="0 0 108 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M15.5633 6.55316L14.564 10.284H10.5768L11.4999 6.83951C11.912 5.1935 10.8447 4.82269 9.63101 4.88449H7.62815L3.42875 20.5534C1.30639 18.3615 0 15.3744 0 12.0824C0 6.29771 4.03043 1.45447 9.43938 0.20606C13.5955 -0.751875 16.8718 1.67489 15.5633 6.55316Z"
				fill="white"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M8.65652 17.4467L9.65588 13.7159H13.643L12.7199 17.1603C12.3078 18.8063 13.3752 19.1772 14.5888 19.1154H16.5917L20.7911 3.44641C22.9114 5.64039 24.2178 8.62544 24.2178 11.9174C24.2178 17.7021 20.1874 22.5454 14.7784 23.7938C10.6243 24.7517 7.34807 22.3249 8.65652 17.4467Z"
				fill="white"
			/>
			<mask
				id="mask0_1408_8319"
				style="mask-type: alpha"
				maskUnits="userSpaceOnUse"
				x="8"
				y="3"
				width="17"
				height="21"
			>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M8.65652 17.4467L9.65588 13.7159H13.643L12.7199 17.1603C12.3078 18.8063 13.3752 19.1772 14.5888 19.1154H16.5917L20.7911 3.44641C22.9114 5.64039 24.2178 8.62544 24.2178 11.9174C24.2178 17.7021 20.1874 22.5454 14.7784 23.7938C10.6243 24.7517 7.34807 22.3249 8.65652 17.4467Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask0_1408_8319)">
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M11.366 21.6118C5.01124 21.6118 -0.140137 16.4616 -0.140137 10.1083C-0.140137 3.75505 5.01124 -1.39514 11.366 -1.39514C17.7207 -1.39514 22.8721 3.75505 22.8721 10.1083C22.8721 16.4616 17.7207 21.6118 11.366 21.6118Z"
					fill="white"
				/>
			</g>
			<path
				d="M102.947 14.7524C102.947 15.6404 102.718 16.3307 102.257 16.8232C101.795 17.3157 101.167 17.5601 100.376 17.5601C99.5962 17.5601 98.9796 17.3157 98.5181 16.8232C98.0566 16.3307 97.8278 15.6404 97.8278 14.7524V7.5354H93.7792V15.2992C93.7792 16.4587 93.9925 17.467 94.4229 18.324C94.8534 19.1811 95.4545 19.8364 96.234 20.2902C97.0134 20.7439 97.9093 20.9688 98.9253 20.9688C99.7823 20.9688 100.566 20.7904 101.271 20.4337C101.977 20.0769 102.54 19.6038 102.951 19.0182V20.8292H107.023V7.5354H102.951V14.7524H102.947Z"
				fill="white"
			/>
			<path
				d="M92.767 7.53508H87.7411L83.7157 12.941V3.19946H79.6438V20.825H83.7157V15.2523L87.7682 20.825H92.8174L87.2408 14.2053L92.767 7.53508Z"
				fill="white"
			/>
			<path
				d="M78.5194 7.53508H73.4935L69.4681 12.941V3.19946H65.3923V20.825H69.4681V15.2523L73.5168 20.825H78.5659L72.9932 14.2053L78.5194 7.53508Z"
				fill="white"
			/>
			<path
				d="M59.0747 9.41583C58.6946 8.79534 58.1478 8.29508 57.442 7.91504C56.7362 7.53499 55.9141 7.34497 54.9756 7.34497C53.8781 7.34497 52.8892 7.62419 51.9973 8.17874C51.1092 8.7333 50.4034 9.52829 49.8877 10.5598C49.3719 11.5914 49.1121 12.7897 49.1121 14.1586C49.1121 15.5237 49.3719 16.7259 49.8877 17.7691C50.4034 18.8084 51.1054 19.6111 51.9973 20.1734C52.8854 20.7357 53.8704 21.0188 54.9523 21.0188C55.8908 21.0188 56.713 20.8288 57.4188 20.4488C58.1246 20.0687 58.6791 19.5685 59.0747 18.948V20.8288H63.1466V7.53499H59.0747V9.41583V9.41583ZM59.0747 14.178C59.0747 15.1941 58.7916 15.9968 58.2293 16.5824C57.667 17.1718 56.9767 17.4627 56.17 17.4627C55.3634 17.4627 54.6731 17.1641 54.1108 16.5708C53.5485 15.9735 53.2654 15.1708 53.2654 14.1548C53.2654 13.1387 53.5485 12.3398 54.1108 11.762C54.6731 11.1842 55.3595 10.8933 56.17 10.8933C56.9805 10.8933 57.667 11.1881 58.2293 11.7737C58.7955 12.3631 59.0747 13.162 59.0747 14.178Z"
				fill="white"
			/>
			<path
				d="M42.5158 7.39172C41.6278 7.39172 40.825 7.56623 40.1114 7.91525C39.3979 8.26427 38.8317 8.73351 38.4206 9.31908V3.19958H34.3448V20.8251H38.4168V13.6082C38.4168 12.7201 38.6456 12.0298 39.107 11.5373C39.5685 11.0448 40.1851 10.8005 40.9646 10.8005C41.7441 10.8005 42.3607 11.0448 42.8222 11.5373C43.2837 12.0298 43.5125 12.7201 43.5125 13.6082V20.8251H47.5611V13.0614C47.5611 11.2969 47.0996 9.91242 46.1805 8.90414C45.2614 7.89586 44.0399 7.39172 42.5158 7.39172Z"
				fill="white"
			/>
		</svg>
	</div>
</template>

<script>
	export default {};
</script>

<style></style>
