<template>
  <div>
    <svg
      width="129"
      height="32"
      viewBox="0 0 129 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M123.811 18.6078C123.811 19.6659 123.536 20.4897 122.987 21.079C122.437 21.6684 121.687 21.9563 120.742 21.9563C119.817 21.9563 119.074 21.6617 118.524 21.079C117.975 20.4897 117.7 19.6659 117.7 18.6078V10.0019H112.869V19.2574C112.869 20.637 113.123 21.8425 113.632 22.8605C114.142 23.8851 114.865 24.662 115.79 25.2045C116.715 25.7469 117.787 26.0148 119 26.0148C120.025 26.0148 120.957 25.8005 121.801 25.3786C122.645 24.95 123.309 24.3874 123.804 23.6909V25.8474H128.663V10.0019H123.804V18.6078H123.811Z"
        fill="url(#paint0_linear_79_2623)"
      />
      <path
        d="M111.676 10.0019H105.685L100.881 16.4446V4.8384H96.0292V25.8474H100.881V19.2038L105.712 25.8474H111.736L105.089 17.9514L111.676 10.0019Z"
        fill="url(#paint1_linear_79_2623)"
      />
      <path
        d="M94.6824 10.0019H88.6917L83.8872 16.4446V4.8384H79.0357V25.8474H83.8872V19.2038L88.7185 25.8474H94.7427L88.0954 17.9514L94.6824 10.0019Z"
        fill="url(#paint2_linear_79_2623)"
      />
      <path
        d="M71.4972 12.2455C71.0415 11.5088 70.3915 10.9127 69.5539 10.4573C68.7096 10.0019 67.7313 9.7742 66.6122 9.7742C65.3055 9.7742 64.1195 10.1024 63.0607 10.7654C62.002 11.4284 61.1644 12.3727 60.5479 13.605C59.9314 14.8373 59.6232 16.2637 59.6232 17.8912C59.6232 19.5186 59.9314 20.9518 60.5479 22.1907C61.1644 23.4297 62.002 24.3874 63.0607 25.0571C64.1195 25.7268 65.2921 26.0617 66.5854 26.0617C67.7045 26.0617 68.6828 25.834 69.5271 25.3786C70.3714 24.9232 71.0281 24.3271 71.5039 23.5904V25.8474H76.362V10.0019H71.5039V12.2455H71.4972ZM71.4972 17.9246C71.4972 19.1368 71.1621 20.0945 70.492 20.791C69.8219 21.4942 68.9977 21.8425 68.0328 21.8425C67.0679 21.8425 66.2504 21.4875 65.5736 20.7776C64.9035 20.0677 64.5684 19.11 64.5684 17.8979C64.5684 16.6857 64.9035 15.7347 65.5736 15.0449C66.2437 14.3551 67.0679 14.0068 68.0328 14.0068C68.9977 14.0068 69.8152 14.3551 70.492 15.0583C71.1621 15.7548 71.4972 16.7125 71.4972 17.9246Z"
        fill="url(#paint3_linear_79_2623)"
      />
      <path
        d="M51.7499 9.83449C50.6911 9.83449 49.7329 10.0421 48.8819 10.4573C48.0309 10.8725 47.3541 11.4351 46.8649 12.1316V4.83841H42.0067V25.8474H46.8649V17.2415C46.8649 16.1834 47.1396 15.3596 47.6891 14.7703C48.2386 14.1809 48.9757 13.893 49.9071 13.893C50.8318 13.893 51.5756 14.1876 52.1251 14.7703C52.6746 15.3596 52.9493 16.1834 52.9493 17.2415V25.8474H57.7807V16.5919C57.7807 14.489 57.2312 12.8415 56.1323 11.636C55.0266 10.4305 53.5658 9.83449 51.7499 9.83449Z"
        fill="url(#paint4_linear_79_2623)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.1429 8.48158L18.8496 13.3102H13.6899L14.8827 8.84993C15.4187 6.72023 14.0316 6.23804 12.4636 6.3184H9.87037L4.43594 26.5974C1.68857 23.7578 -6.10352e-05 19.8935 -6.10352e-05 15.6341C-6.10352e-05 8.14673 5.21994 1.87819 12.2224 0.264173C17.5898 -0.968104 21.8315 2.16617 20.1429 8.48158Z"
        fill="url(#paint5_linear_79_2623)"
      />
      <mask
        id="mask0_79_2623"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="21"
        height="27"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.1429 8.48181L18.8496 13.3105H13.6899L14.8827 8.85015C15.4188 6.72045 14.0317 6.23826 12.4637 6.31862H9.8704L4.43597 26.5976C1.6886 23.758 -3.05176e-05 19.8938 -3.05176e-05 15.6344C-3.05176e-05 8.14695 5.21997 1.87841 12.2224 0.264394C17.5898 -0.967883 21.8315 2.16639 20.1429 8.48181Z"
          fill="url(#paint6_linear_79_2623)"
        />
      </mask>
      <g mask="url(#mask0_79_2623)">
        <path
          d="M16.6316 32.8661C24.8585 32.8661 31.5277 26.2006 31.5277 17.9783C31.5277 9.756 24.8585 3.09052 16.6316 3.09052C8.40472 3.09052 1.7355 9.756 1.7355 17.9783C1.7355 26.2006 8.40472 32.8661 16.6316 32.8661Z"
          fill="url(#paint7_linear_79_2623)"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.1971 22.5859L12.4903 17.7572H17.65L16.4573 22.2175C15.9212 24.3472 17.3083 24.8294 18.8763 24.7491H21.4696L26.904 4.47007C29.6514 7.30966 31.34 11.1739 31.34 15.4333C31.34 22.9207 26.12 29.1893 19.1175 30.8033C13.7434 32.0356 9.50174 28.9013 11.1971 22.5859Z"
        fill="url(#paint8_linear_79_2623)"
      />
      <mask
        id="mask1_79_2623"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="10"
        y="4"
        width="22"
        height="28"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.1971 22.5859L12.4903 17.7572H17.65L16.4573 22.2175C15.9212 24.3472 17.3083 24.8294 18.8763 24.7491H21.4696L26.904 4.47009C29.6514 7.30968 31.34 11.1739 31.34 15.4333C31.34 22.9207 26.12 29.1893 19.1175 30.8033C13.7434 32.0356 9.50174 28.9013 11.1971 22.5859Z"
          fill="url(#paint9_linear_79_2623)"
        />
      </mask>
      <g mask="url(#mask1_79_2623)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.7085 27.9771C6.47975 27.9771 -0.187653 21.3134 -0.187653 13.0893C-0.187653 4.86521 6.47975 -1.79846 14.7085 -1.79846C22.9372 -1.79846 29.6046 4.86521 29.6046 13.0893C29.6046 21.3067 22.9305 27.9771 14.7085 27.9771Z"
          fill="url(#paint10_linear_79_2623)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_79_2623"
          x1="116.774"
          y1="9.27892"
          x2="128.569"
          y2="28.6057"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#4D4D4D" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_79_2623"
          x1="96.7858"
          y1="6.35784"
          x2="110.949"
          y2="29.5644"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#4D4D4D" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_79_2623"
          x1="79.7922"
          y1="6.35784"
          x2="93.9551"
          y2="29.5644"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#4D4D4D" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_79_2623"
          x1="65.2712"
          y1="10.9253"
          x2="76.0055"
          y2="28.514"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#4D4D4D" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_79_2623"
          x1="41.8886"
          y1="6.89531"
          x2="56.0675"
          y2="30.1282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#4D4D4D" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_79_2623"
          x1="2.02362"
          y1="24.7734"
          x2="19.1938"
          y2="-4.19443"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#000066" />
          <stop offset="1" stop-color="#6FC9F1" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_79_2623"
          x1="2.02365"
          y1="24.7736"
          x2="19.1938"
          y2="-4.19421"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#000066" />
          <stop offset="1" stop-color="#6FC9F1" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_79_2623"
          x1="21.1929"
          y1="8.01151"
          x2="10.1345"
          y2="32.2088"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#003399" />
          <stop offset="1" stop-color="#0DA9E4" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_79_2623"
          x1="29.3143"
          y1="6.294"
          x2="12.1441"
          y2="35.2618"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#769E28" />
          <stop offset="1" stop-color="#D1E28C" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_79_2623"
          x1="29.3143"
          y1="6.29401"
          x2="12.1441"
          y2="35.2619"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#769E28" />
          <stop offset="1" stop-color="#D1E28C" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_79_2623"
          x1="10.1451"
          y1="23.0561"
          x2="21.2035"
          y2="-1.14126"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#769E28" />
          <stop offset="1" stop-color="#D1E28C" />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
