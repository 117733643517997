import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "./assets/main.scss";
import VueSweetalert2 from 'vue-sweetalert2';
import '@sweetalert2/theme-bootstrap-4/bootstrap-4.scss';
import VueMeta from 'vue-meta'


Vue.config.productionTip = false
Vue.use(VueSweetalert2);
Vue.use(VueMeta)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
