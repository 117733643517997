import { render, staticRenderFns } from "./HomeKeyFeaturesCard.vue?vue&type=template&id=24ba4aca&scoped=true&"
import script from "./HomeKeyFeaturesCard.vue?vue&type=script&lang=js&"
export * from "./HomeKeyFeaturesCard.vue?vue&type=script&lang=js&"
import style0 from "./HomeKeyFeaturesCard.vue?vue&type=style&index=0&id=24ba4aca&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24ba4aca",
  null
  
)

export default component.exports