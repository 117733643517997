<template>
	<section id="services" class="key-features">
		<div class="container-md">
			<HomeKeyFeaturesHero class="hero" />
			<HomeKeyFeaturesCard class="card-item" />
		</div>
		<img
			src="../../../assets/images/key-features-left.webp"
			class="key-features-icon-left d-sm-flex d-none"
			alt=""
		/>
	</section>
</template>

<script>
	import HomeKeyFeaturesHero from "../components/HomeKeyFeaturesHero.vue";
	import HomeKeyFeaturesCard from "../components/HomeKeyFeaturesCard.vue";
	export default { components: { HomeKeyFeaturesHero, HomeKeyFeaturesCard } };
</script>

<style lang="scss" scoped>
	.key-features {
		position: relative;
		@media (min-width: 767.98px) {
			background: linear-gradient(
				270.22deg,
				#eafab6 0.18%,
				#a0cf43 21.14%,
				#3a630c 55.62%,
				#152503 92%
			);
		}

		.container {
			@media (max-width: 767.98px) {
				background: rgba(21, 37, 3, 1);
			}
		}

		.hero {
			z-index: 99;
			padding-top: 80px;
			padding-bottom: 60px;

			@media (max-width: 767.98px) {
				padding-top: 0px;
				padding-bottom: 20px;
			}
		}

		.card-item {
			z-index: 99;
			padding-top: 60px;
			padding-bottom: 80px;

			@media (max-width: 767.98px) {
				padding-top: 20px;
				padding-bottom: 32px;
			}
		}

		.key-features-icon-left {
			z-index: 9;
			position: absolute;
			top: 0;
		}
	}
</style>
