<template>
	<nav class="navbar navbar-expand-lg">
		<div class="container">
			<HakkuIcon class="my-2" />
			<div
				class="justify-content-end collapse navbar-collapse"
				id="navbarNavAltMarkup"
			>
				<div class="align-items-center gap-20 navbar-nav">
					<a
						class="nav-link font-weight-semibold color--black-primary"
						aria-current="page"
						href="#about"
						><p>Tentang hakku</p></a
					>
					<a
						class="nav-link font-weight-semibold color--black-primary"
						href="#services"
						><p>Layanan</p></a
					>
					<a class="nav-link" href="#start-now"
						><button
							type="button"
							class="btn btn-green btn-lg ripple font-weight-semibold color--black-primary"
						>
							<p>Mulai Sekarang</p>
						</button>
					</a>
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
	import HakkuIcon from "../icons/HakkuIcon.vue";

	export default {
		components: {
			HakkuIcon,
		},
	};
</script>

<style lang="scss" scoped>
	.navbar {
		background-color: white;
		box-shadow: 0 1px 8px 0 rgb(0 0 0 / 12%);
	}
</style>
