<template>
	<section class="workflow">
		<div class="container main">
			<h2
				class="subtitle color--black-primary text-center font-weight-semibold"
			>
				Cara pakai hakku
			</h2>
			<div class="row">
				<div
					class="list col-md-4 col-12 mb-md-0 mb-5"
					v-for="item in items"
					:key="item.id"
				>
					<img
						:src="require('../../../assets/images/' + item.img)"
						class="workflow-img mb-4 d-block mx-auto"
						alt="Workflow"
					/>
					<img
						:src="require('../../../assets/images/' + item.number)"
						class="d-block mx-auto mb-2"
						width="40"
						alt="Point"
					/>
					<h5
						class="color--black-primary font-weight-semibold text-center mb-2"
					>
						{{ item.title }}
					</h5>
					<h6 class="caption color--black-primary text-center">
						{{ item.caption }}
					</h6>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		data() {
			return {
				items: [
					{
						id: 1,
						img: "step1.webp",
						number: "point1.webp",
						title: "Daftar",
						caption: "Daftarkan perusahaan Anda untuk bergabung dengan Hakku",
					},
					{
						id: 2,
						img: "step2.webp",
						number: "point2.webp",
						title: "Terhubung",
						caption:
							"Kami terhubung dengan PIC di perusahaan Anda untuk membantu Anda masuk ke platform kami",
					},
					{
						id: 3,
						img: "step3.webp",
						number: "point3.webp",
						title: "Tarik Gaji",
						caption:
							"Karyawan Anda dapat mengakses penghasilan prorata mereka kapanpun mereka butuhkan",
					},
				],
			};
		},
	};
</script>

<style lang="scss" scoped>
	.workflow {
		.main {
			padding-top: 40px;
			padding-bottom: 64px;

			@media (max-width: 991.98px) {
				padding-top: 24px;
				padding-bottom: 24px;
			}
		}

		.subtitle {
			margin-bottom: 80px;
			@media (max-width: 991.98px) {
				margin-bottom: 48px;
			}
		}

		.workflow-img {
			height: 150px;
		}

		.caption {
			opacity: 0.7;
			@media (min-width: 991.98px) {
				height: 81px;
				display: flex;
				align-items: center;
			}
		}

		h5 {
			@media (max-width: 991.98px) {
				font-size: 18px;
				line-height: 27px;
			}
		}

		.list:last-child {
			@media (max-width: 991.98px) {
				margin-bottom: 24px !important;
			}
		}
	}
</style>
