<template>
	<section id="start-now" class="contact">
		<div class="container">
			<div class="row">
				<div
					class="col-lg-7 col-md-6 col-12 mb-md-0 mb-4 d-flex flex-column justify-content-center"
				>
					<h2
						class="color--black-primary mb-md-2 mb-3 text-md-start text-center font-weight-semibold"
					>
						Hubungi kami
					</h2>
					<h6
						class="caption text-md-start text-md-start text-center color--black-primary"
					>
						Anda tertarik untuk bergabung dengan hakku?<br
							class="d-md-block d-none"
						/>
						Silahkan hubungi kami dengan mengisi form berikut.
					</h6>
				</div>
				<div class="col-lg-5 col-md-6 col-12">
					<form>
						<div class="card mb-3">
							<input
								type="picFullname"
								class="form-control mb-3"
								id="inputpicFullname"
								aria-describedby="picFullname"
								placeholder="Nama Lengkap"
								v-model="picFullname"
							/>
							<input
								type="number"
								class="form-control mb-3"
								id="inputpicMobileNumber"
								aria-describedby="picMobileNumber"
								placeholder="Nomor Telepon"
								v-model="picMobileNumber"
							/>
							<input
								type="email"
								class="form-control mb-3"
								id="inputpicEmail"
								aria-describedby="picEmail"
								placeholder="Email"
								v-model="picEmail"
							/>
							<input
								type="companyName"
								class="form-control mb-3"
								id="inputcompanyName"
								aria-describedby="companyName"
								placeholder="Nama Perusahaan"
								v-model="companyName"
							/>
							<select
								class="form-select mb-3"
								:class="{ 'color-active': isEmployeeSelected }"
								aria-label="Employee"
								v-model="companyTotalEmployee"
							>
								<option value="" disabled>Jumlah Karyawan</option>
								<option
									v-for="totalEmployee in totalEmployees"
									:key="totalEmployee.id"
									:value="totalEmployee"
								>
									{{ totalEmployee }}
								</option>
							</select>
							<textarea
								class="form-control"
								placeholder="Alamat Perusahaan"
								id="companyAddress"
								v-model="companyAddress"
								style="height: 100px"
							></textarea>
						</div>
						<button
							type="button"
							class="btn btn-green btn-lg d-block mx-md-0 mx-auto ripple font-weight-semibold color--black-primary"
							@click="onSubmit"
						>
							<p>Kirim</p>
						</button>
					</form>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import axios from "axios";

	const BASE_URL = process.env.VUE_APP_HAKKU_API_URL;
	export default {
		data() {
			return {
				isEmployeeSelected: false,
				picFullname: "",
				picMobileNumber: "",
				picEmail: "",
				companyName: "",
				companyTotalEmployee: "",
				companyAddress: "",
				resDataMessage: "",
				totalEmployees: [],
				isErr: false,
				auth: {
					username: "123",
					password: "123",
				},
			};
		},
		watch: {
			companyTotalEmployee() {
				this.isEmployeeSelected = true;
			},
		},
		mounted() {
			axios
				.get(`${BASE_URL}/v1/list/total-employees`, { auth: this.auth })
				.then(({ data }) => (this.totalEmployees = data.data));
		},
		methods: {
			onSubmit() {
				const data = {
					picFullname: this.picFullname,
					picMobileNumber: this.picMobileNumber,
					picEmail: this.picEmail,
					companyName: this.companyName,
					companyTotalEmployee: this.companyTotalEmployee,
					companyAddress: this.companyAddress,
				};

				axios
					.post(`${BASE_URL}/v1/auth/register`, data, {
						auth: this.auth,
					})
					.then(({ data }) => {
						this.resDataMessage = data.message;
						this.isErr = false;
					})
					.catch(({ response: err }) => {
						this.resDataMessage = err.data.message;
						this.isErr = true;
					})
					.then(() => {
						this.onAlert(this.resDataMessage, this.isErr);
						if (!this.isErr) {
							this.picFullname = "";
							this.picMobileNumber = "";
							this.picEmail = "";
							this.companyName = "";
							this.companyTotalEmployee = "";
							this.companyAddress = "";
						}
					});
			},
			onAlert(params, err) {
				this.$swal({
					title: params,
					timer: 2000,
					showConfirmButton: false,
					position: "top",
					allowOutsideClick: false,
					backdrop: false,
					color: "#FFFFFF",
					background: err ? "#FF5252" : "#4CAF50",
					customClass: {
						title: "swal2-title-custom",
					},
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.contact {
		padding-top: 85px;
		padding-bottom: 85px;
		@media (max-width: 991.98px) {
			padding-top: 40px;
			padding-bottom: 40px;
		}

		.caption {
			opacity: 0.7;
		}

		.card {
			background: rgba(234, 234, 234, 1);
			border: 0;
			border-radius: 8px;
			padding: 24px;
			@media (max-width: 767.98px) {
				padding: 16px;
			}
		}

		.btn {
			width: 210px;
			@media (max-width: 767.98px) {
				width: 165px;
			}
		}

		.form-control,
		.form-select {
			height: 53px;
			font-size: 16px;
		}
		.form-control::placeholder,
		.form-select {
			color: #c1c1c1;
		}

		option,
		input,
		.form-control,
		.form-select.color-active {
			color: #333333;
		}

		select option[disabled]:first-child {
			display: none;
		}

		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		input[type="number"] {
			-moz-appearance: textfield;
		}
	}
</style>

<style>
	.swal2-title-custom {
		padding: 0px !important;
		line-height: normal !important;
		font-size: 16px !important;
		font-weight: 500 !important;
	}
</style>
