<template>
	<div class="row hero">
		<div
			class="col-md-7 col-12 order-md-1 order-2 d-flex flex-column justify-content-center"
		>
			<div class="container">
				<h2
					class="color--white text-md-start text-center mb-md-5c mb-3 font-weight-semibold"
				>
					Aplikasi Andalan Karyawan
				</h2>
				<h6 class="caption text-md-start text-center color--white">
					Tarik gaji tanpa ribet, langsung cair ke rekening Anda dalam 1x24<br
						class="d-md-block d-none"
					/>
					jam setelah approval
				</h6>
			</div>
		</div>
		<div class="col-md-5 col-12 hero-img order-md-2 order-1">
			<div class="container d-flex justify-content-center">
				<img
					src="../../../assets/images/key-features.webp"
					class="key-features-img"
					alt="Key Features"
				/>
			</div>
		</div>
		<img
			src="../../../assets/images/key-features-right-mobile.webp"
			class="key-features-icon-right-mobile d-sm-none d-flex"
			alt=""
		/>
	</div>
</template>

<script>
	export default {};
</script>

<style lang="scss" scoped>
	.hero {
		position: relative;
		@media (max-width: 767.98px) {
			background: rgba(21, 37, 3, 1);
		}

		&-img {
			@media (max-width: 767.98px) {
				padding-top: 64px;
				background: linear-gradient(
					180deg,
					#ffffff 6.7%,
					#eafab6 35.19%,
					#a0cf43 55.27%,
					#1f3208 94.03%,
					#152503 98.65%
				);
			}
		}
	}
	.key-features-img {
		width: 360px;
		@media (max-width: 1200px) {
			max-width: 100%;
			height: auto;
		}
		@media (max-width: 575.98px) {
			max-width: 195.35px;
			height: auto;
			padding-bottom: 80px;
		}
	}

	.container {
		z-index: 99;
	}

	.key-features-icon-right-mobile {
		position: absolute;
		right: 0;
		top: 360px;
		width: 115px;
		z-index: 9;
	}

	.row > * {
		@media (max-width: 767.98px) {
			padding-left: 0px;
			padding-right: 0px;
		}
	}
</style>
