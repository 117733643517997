<template>
	<section class="faq">
		<div class="container">
			<div class="row">
				<div
					class="col-lg-6 col-12 d-flex justify-md-content-start justify-content-center align-items-center"
				>
					<h2
						class="subtitle color--white text-lg-start text-center font-weight-semibold"
					>
						Punya pertanyaan seputar hakku?
					</h2>
				</div>
				<div class="col-lg-6 col-12">
					<div class="accordion accordion-flush" id="accordionFAQ">
						<div v-for="data in datasFaq" :key="data.id" class="accordion-item">
							<h2 class="accordion-header" id="flush-headingOne">
								<button
									class="accordion-button collapsed"
									type="button"
									data-bs-toggle="collapse"
									:data-bs-target="'#' + data.id"
									aria-expanded="false"
									:aria-controls="data.id"
								>
									<h4 class="font-weight-semibold">
										{{ data.title }}
									</h4>
								</button>
							</h2>
							<div
								:id="data.id"
								class="accordion-collapse collapse"
								aria-labelledby="flush-headingOne"
								data-bs-parent="#accordionFAQ"
							>
								<h6 class="accordion-body" v-html="data.description"></h6>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<img
			src="../../../assets/images/faq-left.webp"
			class="faq-icon-left d-sm-flex d-none"
			alt=""
		/>
		<img
			src="../../../assets/images/faq-left-mobile.webp"
			class="faq-icon-left-mobile d-sm-none d-flex"
			alt=""
		/>
	</section>
</template>

<script>
	export default {
		data() {
			return {
				datasFaq: [
					{
						id: "flush-collapseOne",
						title: "Siapa saja yang dapat menggunakan aplikasi hakku?",
						description:
							"Karyawan yang perusahaannya sudah terdaftar di sistem hakku",
					},
					{
						id: "flush-collapseTwo",
						title:
							"Adakah biaya untuk setiap transaksi yang dilakukan melalui aplikasi hakku?",
						description: `Ya, karyawan akan dikenakan biaya admin untuk setiap penarikan gaji.<br>
								<div class="d-flex"><div class="me-2">•</div><div>Jika nominal penarikan <= Rp1.000.000, biaya admin = Rp27.500 </div></div>
								<div class="d-flex"><div class="me-2">•</div><div>Jika nominal penarikan > Rp1.000.000, biaya admin = Rp32.500 </div></div>`,
					},
					{
						id: "flush-collapseThree",
						title: "Berapakah nominal yang dapat di tarik melalui hakku?",
						description: `Karyawan dapat melakukan penarikan maksimal 50% dari gaji bulanannya sesuai dengan limit minimum yang telah ditetapkan.
									<br><br>
									Limit minimum tersebut tergantung dari penerimaan gaji masing-masing karyawan, dengan perhitungan gaji minimal 5 hari kerja dan penarikan hanya bisa dilakukan di 50%-nya saja.`,
					},
					{
						id: "flush-collapseFour",
						title: "Kapan dana harus dikembalikan?",
						description:
							"Gaji yang di akses karyawan melalui hakku akan dihitung sebagai potongan pada siklus gajian bulan berikutnya.",
					},
				],
			};
		},
	};
</script>

<style lang="scss" scoped>
	.faq {
		position: relative;
		background: rgba(21, 37, 3, 1);
		padding-top: 56px;
		padding-bottom: 56px;
		@media (max-width: 991.98px) {
			padding-top: 32px;
			padding-bottom: 80px;
		}

		.subtitle {
			@media (max-width: 991.98px) {
				margin-bottom: 32px;
			}
		}

		.faq-icon-left,
		.faq-icon-left-mobile {
			position: absolute;
			top: 0;
		}

		.faq-icon-left-mobile {
			width: 76px;
			margin-left: 0px;
		}

		.accordion-item {
			border-bottom: 1px solid #ffffff !important;
		}
		.accordion-button,
		.accordion-body {
			padding-left: 0px;
		}
		.accordion-flush .accordion-item .accordion-button,
		.accordion-flush .accordion-item .accordion-button.collapsed {
			background: rgba(21, 37, 3, 1);
		}
		.accordion-flush .accordion-item .accordion-button > h4 {
			color: #bfe26f;
		}
		.accordion-flush .accordion-item .accordion-button.collapsed > h4 {
			color: #ffffff;
		}
		.accordion-body {
			background: rgba(21, 37, 3, 1);
			color: rgba(255, 255, 255, 0.8);
		}
		.accordion-button.collapsed::after {
			background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.5 5.47559V19.4756' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.5 12.4756H19.5' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
		}
		.accordion-button::after {
			background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 12.9756H19' stroke='%23BFE26F' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
		}

		.list-item::before {
			content: "●";
			margin-right: 0.75rem;
		}
	}
</style>
