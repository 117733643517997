<template>
	<section class="header">
		<div class="container main">
			<div class="row">
				<div
					class="col-md-7 col-12 order-md-1 order-2 d-flex mt-md-0 mt-4 flex-column justify-content-center"
				>
					<h1 class="color--white mb-md-5c mb-3 font-weight-semibold">
						Andalan Finansial<br class="d-md-none d-block" />
						untuk Karyawan
					</h1>
					<h5 class="caption color--white mb-md-5c mb-3">
						Mengedepankan hak dan kesejahteraan karyawan<br
							class="d-md-block d-none"
						/>
						dengan memberi akses fleksibel atas gaji lewat platform<br
							class="d-md-block d-none"
						/>
						keuangan hakku.
					</h5>
					<div>
						<a href="#start-now">
							<button
								type="button"
								class="btn btn-green btn-xl ripple font-weight-semibold color--black-primary"
							>
								<p>Mulai Sekarang</p>
							</button>
						</a>
					</div>
				</div>
				<div class="col-md-5 col-12 order-md-2 order-1">
					<div class="justify-content-center align-items-center h-100 d-flex">
						<div v-if="showImg">
							<img :src="imgUrl" class="header-img" alt="Header Image" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<img
			src="../../../assets/images/header-left.webp"
			class="header-icon-left"
			alt=""
		/>
		<img
			src="../../../assets/images/header-right.webp"
			class="header-icon-right"
			alt=""
		/>
	</section>
</template>

<script>
	export default {
		data() {
			return {
				showImg: false,
				imgUrl: null,
			};
		},
		mounted() {
			let img = new Image();

			img.onload = () => {
				this.imgUrl = img.src;
				this.showImg = true;
			};

			img.src = require("../../../assets/images/header.webp");
		},
	};
</script>

<style lang="scss" scoped>
	.header {
		background: linear-gradient(231.86deg, #003399 13.83%, #0da9e4 98.53%);
		position: relative;
		.main {
			z-index: 99;
			padding-top: 44px;
			padding-bottom: 44px;

			@media (max-width: 991.98px) {
				padding-top: 16px;
				padding-bottom: 44px;
			}
		}

		.header-img {
			width: 457.81px;
			@media (max-width: 1200px) {
				max-width: 100%;
				height: auto;
			}
			@media (max-width: 575.98px) {
				max-width: 296.75px;
				height: auto;
			}
		}

		.header-icon-left {
			z-index: 9;
			position: absolute;
			bottom: 32px;
			@media (max-width: 767.98px) {
				bottom: 350px;
				height: 80px;
			}
		}

		.header-icon-right {
			z-index: 9;
			position: absolute;
			right: 0;
			top: 60px;
			@media (max-width: 767.98px) {
				top: 37px;
				height: 80px;
			}
		}
	}
</style>
