<template>
	<section>
		<NavbarMain class="navbar" />
		<HomeHeader class="header" />
		<HomeDescription />
		<HomeWorkflow />
		<HomeKeyFeatures />
		<HomeFAQ />
		<HomeContact />
		<FooterMain />
	</section>
</template>

<script>
	import NavbarMain from "../components/navbar/NavbarMain.vue";
	import HomeHeader from "../components/home/containers/HomeHeader.vue";
	import HomeDescription from "../components/home/containers/HomeDescription.vue";
	import HomeWorkflow from "../components/home/containers/HomeWorkflow.vue";
	import HomeKeyFeatures from "../components/home/containers/HomeKeyFeatures.vue";
	import HomeFAQ from "@/components/home/containers/HomeFAQ.vue";
	import HomeContact from "@/components/home/containers/HomeContact.vue";
	import FooterMain from "@/components/footer/FooterMain.vue";

	export default {
		components: {
			NavbarMain,
			HomeHeader,
			HomeDescription,
			HomeWorkflow,
			HomeKeyFeatures,
			HomeFAQ,
			HomeContact,
			FooterMain,
		},
		metaInfo: {
			title: "Hakku",
			titleTemplate: "%s - Andalan Finansial untuk Karyawan",
			meta: [
				{
					name: "og:title",
					content: "Hakku - Andalan Finansial untuk Karyawan",
				},
				{
					name: "og:site-name",
					content: "Hakku",
				},
				{
					name: "description",
					content:
						"Memberi akses fleksibel atas gaji karyawan lewat platform keuangan hakku.",
				},
				{
					name: "og:description",
					content:
						"Memberi akses fleksibel atas gaji karyawan lewat platform keuangan hakku.",
				},
			],
		},
	};
</script>

<style lang="scss" scoped>
	.navbar {
		position: fixed;
		left: 0;
		top: 0;
		width: 100vw;
		z-index: 200;
	}
	.header {
		margin-top: 64px;
	}
</style>
