<template>
	<section id="about" class="description">
		<div class="container main">
			<div class="row">
				<div class="col-12 d-md-none d-block">
					<h2 class="color--black-primary text-center font-weight-semibold">
						Hakku - Menjaga hak<br class="d-md-none d-block" />
						setiap karyawan
					</h2>
				</div>
				<div class="col-md-6 col-12">
					<div
						class="py-md-0 py-3 justify-content-center align-items-center h-100 d-flex"
					>
						<img
							class="description-img"
							src="../../../assets/images/description.webp"
							alt="Description Image"
						/>
					</div>
				</div>
				<div class="col-md-6 col-12 d-flex flex-column justify-content-center">
					<h2
						class="d-md-flex d-none color--black-primary mb-md-5c font-weight-semibold"
					>
						Hakku - Menjaga hak setiap karyawan
					</h2>
					<h6 class="caption text-md-start text-center color--black-primary">
						Platform andalan keuangan untuk karyawan yang memberi<br
							class="d-md-block d-none"
						/>
						kemudahan akses terhadap gaji prorata kapanpun dibutuhkan.
					</h6>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {};
</script>

<style lang="scss" scoped>
	.description {
		.main {
			padding-top: 86px;
			padding-bottom: 86px;

			@media (max-width: 991.98px) {
				padding-top: 24px;
				padding-bottom: 24px;
			}
		}

		.description-img {
			width: 457.81px;
			@media (max-width: 1200px) {
				max-width: 100%;
				height: auto;
			}
			@media (max-width: 575.98px) {
				max-width: 219.23px;
				height: auto;
			}
		}

		.caption {
			opacity: 0.7;
		}
	}
</style>
